import React, { useState, createContext } from "react"
import InitialPostLimit from "../components/initial-post-limit"

export const GlobalContext = createContext({
  limit: InitialPostLimit,
})

const GlobalContextProvider = props => {
  const [state, setState] = useState({ limit: InitialPostLimit })

  const handleClick = e => {
    const {
      target: { name, value },
    } = e
    const id = e.target.getAttribute("data-id")

    const prevStateLimit = state[name] || state.limit

    if (e.target.getAttribute("data-loadmore")) {
      setState({ ...state, [name]: prevStateLimit + parseInt(value) })
    } else {
      setState({ ...state, ["category-" + id]: name, [id]: InitialPostLimit })
    }
  }

  const handleSearch = e => {
    e.preventDefault()

    const {
      target: { name, value },
    } = e

    setState({
      ...state,
      [name]: InitialPostLimit,
      ["category-" + name]: "Alle Kategorien",
      ["suche-" + name]: value,
    })
  }

  const handleChange = e => {
    const {
      target: { name },
    } = e
    const id = e.target.getAttribute("data-id")

    const prevStateDeselected = state["deselectedCategories-" + id] || []

    if (prevStateDeselected.includes(name)) {
      setState({
        ...state,
        [id]: InitialPostLimit,
        ["deselectedCategories-" + id]: prevStateDeselected.filter(
          el => el !== name
        ),
      })
    } else {
      setState({
        ...state,
        [id]: InitialPostLimit,
        ["deselectedCategories-" + id]: [...prevStateDeselected, name],
      })
    }
  }

  const handleSwitch = e => {
    const {
      target: { name },
    } = e
    const id = e.target.getAttribute("data-id")

    const prevStateSwitch = state["switch-" + id] || null

    if (prevStateSwitch) {
      setState({
        ...state,
        ["switch-" + id]: name,
      })
    } else {
      setState({
        ...state,
        ["switch-" + id]: name,
      })
    }
  }

  return (
    <GlobalContext.Provider
      value={{
        ...state,
        handleClick: handleClick,
        handleSearch: handleSearch,
        handleChange: handleChange,
        handleSwitch: handleSwitch,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  )
}

export default GlobalContextProvider
