import React from "react"
import GlobalContextProvider from "./src/context/global-context"

// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// custom CSS styles
import "./src/sass/style.scss"

export const wrapRootElement = ({ element }) => (
  <GlobalContextProvider>{element}</GlobalContextProvider>
)
